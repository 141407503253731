import React from 'react';
import styled, { css } from 'styled-components';
import WebsiteDefaultPage from '../components/Page/Base';
import { Color, Fonts, paddingTopBottom, typographyByBreakpoint, xxlDesktop, xxlMobile, columnStartEnd } from '../styles';
import { ReadyPayUpgrade } from '../components/PageSpecific/products';
import { GreenDivider } from '../components/PageSpecific/homepage';
// @ts-ignore
import { Wrapper } from '../components/PageSpecific/products/BaseHeroComponentStyle';
import { LIGHT_DARK } from '../constants';

const mobilePageTitle = css`
  ${Fonts.QUESTRIAL};
  font-size: 7.4rem;
  font-weight: 400;
  line-height: calc(66 / 80);
  letter-spacing: 0.0125em;
`;

const PageTitle = styled.h1`
  ${columnStartEnd([1, 9], [1, 7], [1, 7])}
  ${typographyByBreakpoint(mobilePageTitle, xxlMobile, xxlDesktop)};
  ${paddingTopBottom([70, 32], [86, 56], [206, 72])};
  color: ${Color.SEAFOAM_GREEN};
  margin-inline: auto;
`;

const Products = () => {
  return (
    <WebsiteDefaultPage bodyBackground={Color.WHITE} pageState={{ theme: LIGHT_DARK.LIGHT }}>
      <Wrapper as={'div'}>
        <PageTitle>Products</PageTitle>
      </Wrapper>
      <ReadyPayUpgrade />
      <GreenDivider/>
    </WebsiteDefaultPage>
  );
};

export default Products;
